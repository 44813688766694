import Vue from "vue";
import Vuex from "vuex";
//import moment from "moment";
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from 'vuex-multi-tab-state';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
   
    set_global_compare:[],
    
  },
  mutations: {
    SET_ADD_TO_COMPARE(state, payload) {
      state.set_global_compare = payload;
    },
   
  
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
    createMultiTabState(),
  ],
  actions: {
    setLayoutType({ commit }, width) {
      commit("SET_LAYOUT_TYPE", width);
    },
    //Calendar Action
    addCalendarEvent({ commit }, event) {
      commit("ADD_CALENDAR_EVENT", event);
    },
  },
  getters: {},
});
